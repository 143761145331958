import { IVideo } from 'app/shared/model/video.model';
import { IVideoCategoryRatingItem } from 'app/shared/model/video-category-rating-item.model';

export interface IVideoCategory {
  id?: number;
  name?: string;
  description?: string | null;
  videos?: IVideo[] | null;
  ratingItems?: IVideoCategoryRatingItem[] | null;
}

export const defaultValue: Readonly<IVideoCategory> = {};
