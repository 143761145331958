import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import VideoFeedback from './video-feedback';
import VideoFeedbackDetail from './video-feedback-detail';
import VideoFeedbackUpdate from './video-feedback-update';
import VideoFeedbackDeleteDialog from './video-feedback-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={VideoFeedbackUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={VideoFeedbackUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={VideoFeedbackDetail} />
      <ErrorBoundaryRoute path={match.url} component={VideoFeedback} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={VideoFeedbackDeleteDialog} />
  </>
);

export default Routes;
