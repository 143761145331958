import { IVideo } from 'app/shared/model/video.model';
import { IVideoFeedback } from 'app/shared/model/video-feedback.model';

export interface IVideoFeedbackRating {
  id?: number;
  rating?: number | null;
  video?: IVideo | null;
  feedback?: IVideoFeedback | null;
}

export const defaultValue: Readonly<IVideoFeedbackRating> = {};
