import React from 'react';
import { Switch } from 'react-router-dom';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Cohort from './cohort';
import CohortMember from './cohort-member';
import UserSettings from './user-settings';
import UserInterest from './user-interest';
import Badge from './badge';
import Work from './work';
import School from './school';
import BadgeRequirement from './badge-requirement';
import UserTag from './user-tag';
import Organization from './organization';
import OrganizationMember from './organization-member';
import Video from './video';
import VideoCategory from './video-category';
import VideoCategoryRatingItem from './video-category-rating-item';
import VideoFeedback from './video-feedback';
import VideoFeedbackRating from './video-feedback-rating';
import VideoRating from './video-rating';
import VideoLike from './video-like';
import WalletAddress from './wallet-address';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default ({ match }) => {
  return (
    <div>
      <Switch>
        {/* prettier-ignore */}
        <ErrorBoundaryRoute path={`${match.url}cohort`} component={Cohort} />
        <ErrorBoundaryRoute path={`${match.url}cohort-member`} component={CohortMember} />
        <ErrorBoundaryRoute path={`${match.url}user-settings`} component={UserSettings} />
        <ErrorBoundaryRoute path={`${match.url}user-interest`} component={UserInterest} />
        <ErrorBoundaryRoute path={`${match.url}badge`} component={Badge} />
        <ErrorBoundaryRoute path={`${match.url}work`} component={Work} />
        <ErrorBoundaryRoute path={`${match.url}school`} component={School} />
        <ErrorBoundaryRoute path={`${match.url}badge-requirement`} component={BadgeRequirement} />
        <ErrorBoundaryRoute path={`${match.url}user-tag`} component={UserTag} />
        <ErrorBoundaryRoute path={`${match.url}organization`} component={Organization} />
        <ErrorBoundaryRoute path={`${match.url}organization-member`} component={OrganizationMember} />
        <ErrorBoundaryRoute path={`${match.url}video`} component={Video} />
        <ErrorBoundaryRoute path={`${match.url}video-category`} component={VideoCategory} />
        <ErrorBoundaryRoute path={`${match.url}video-category-rating-item`} component={VideoCategoryRatingItem} />
        <ErrorBoundaryRoute path={`${match.url}video-feedback`} component={VideoFeedback} />
        <ErrorBoundaryRoute path={`${match.url}video-feedback-rating`} component={VideoFeedbackRating} />
        <ErrorBoundaryRoute path={`${match.url}video-rating`} component={VideoRating} />
        <ErrorBoundaryRoute path={`${match.url}video-like`} component={VideoLike} />
        <ErrorBoundaryRoute path={`${match.url}wallet-address`} component={WalletAddress} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </Switch>
    </div>
  );
};
