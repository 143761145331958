import React from 'react';
import { Translate } from 'react-jhipster';

import MenuItem from 'app/shared/layout/menus/menu-item';

const EntitiesMenu = () => {
  return (
    <>
      {/* prettier-ignore */}
      <MenuItem icon="asterisk" to="/cohort">
        <Translate contentKey="global.menu.entities.cohort" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/cohort-member">
        <Translate contentKey="global.menu.entities.cohortMember" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-settings">
        <Translate contentKey="global.menu.entities.userSettings" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-interest">
        <Translate contentKey="global.menu.entities.userInterest" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/badge">
        <Translate contentKey="global.menu.entities.badge" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/work">
        <Translate contentKey="global.menu.entities.work" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/school">
        <Translate contentKey="global.menu.entities.school" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/badge-requirement">
        <Translate contentKey="global.menu.entities.badgeRequirement" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-tag">
        <Translate contentKey="global.menu.entities.userTag" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/organization">
        <Translate contentKey="global.menu.entities.organization" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/organization-member">
        <Translate contentKey="global.menu.entities.organizationMember" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/video">
        <Translate contentKey="global.menu.entities.video" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/video-category">
        <Translate contentKey="global.menu.entities.videoCategory" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/video-category-rating-item">
        <Translate contentKey="global.menu.entities.videoCategoryRatingItem" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/video-feedback">
        <Translate contentKey="global.menu.entities.videoFeedback" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/video-feedback-rating">
        <Translate contentKey="global.menu.entities.videoFeedbackRating" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/video-rating">
        <Translate contentKey="global.menu.entities.videoRating" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/video-like">
        <Translate contentKey="global.menu.entities.videoLike" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/wallet-address">
        <Translate contentKey="global.menu.entities.walletAddress" />
      </MenuItem>
      {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
    </>
  );
};

export default EntitiesMenu as React.ComponentType<any>;
