import { IVideoCategory } from 'app/shared/model/video-category.model';
import { IVideoFeedback } from 'app/shared/model/video-feedback.model';
import { IVideoFeedbackRating } from 'app/shared/model/video-feedback-rating.model';
import { IVideoLike } from 'app/shared/model/video-like.model';
import { CohortVideoAccessLevel } from 'app/shared/model/enumerations/cohort-video-access-level.model';

export interface IVideo {
  id?: number;
  title?: string;
  description?: string;
  thumbnail?: string | null;
  videoUrl?: string | null;
  accessLevel?: CohortVideoAccessLevel | null;
  category?: IVideoCategory | null;
  feedbacks?: IVideoFeedback[] | null;
  feedbackRatings?: IVideoFeedbackRating[] | null;
  likes?: IVideoLike[] | null;
}

export const defaultValue: Readonly<IVideo> = {};
