import { ICohort } from 'app/shared/model/cohort.model';

export interface ICohortMember {
  id?: number;
  userId?: string | null;
  cohortId?: number | null;
  roleId?: string | null;
  cohort?: ICohort;
}

export const defaultValue: Readonly<ICohortMember> = {};
