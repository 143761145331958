import { IVideoFeedbackRating } from 'app/shared/model/video-feedback-rating.model';
import { IVideoRating } from 'app/shared/model/video-rating.model';
import { IVideo } from 'app/shared/model/video.model';

export interface IVideoFeedback {
  id?: number;
  comment?: string | null;
  feedbackRating?: IVideoFeedbackRating | null;
  ratings?: IVideoRating[] | null;
  video?: IVideo | null;
}

export const defaultValue: Readonly<IVideoFeedback> = {};
