import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import UserInterest from './user-interest';
import UserInterestDetail from './user-interest-detail';
import UserInterestUpdate from './user-interest-update';
import UserInterestDeleteDialog from './user-interest-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={UserInterestUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={UserInterestUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={UserInterestDetail} />
      <ErrorBoundaryRoute path={match.url} component={UserInterest} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={UserInterestDeleteDialog} />
  </>
);

export default Routes;
