import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ICohort } from 'app/shared/model/cohort.model';
import { CohortType } from 'app/shared/model/enumerations/cohort-type.model';
import { CohortVisibility } from 'app/shared/model/enumerations/cohort-visibility.model';
import { CohortMembership } from 'app/shared/model/enumerations/cohort-membership.model';
import { EntityState } from 'app/shared/model/enumerations/entity-state.model';
import { getEntity, updateEntity, createEntity, reset } from './cohort.reducer';

export const CohortUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const cohortEntity = useAppSelector(state => state.cohort.entity);
  const loading = useAppSelector(state => state.cohort.loading);
  const updating = useAppSelector(state => state.cohort.updating);
  const updateSuccess = useAppSelector(state => state.cohort.updateSuccess);
  const cohortTypeValues = Object.keys(CohortType);
  const cohortVisibilityValues = Object.keys(CohortVisibility);
  const cohortMembershipValues = Object.keys(CohortMembership);
  const entityStateValues = Object.keys(EntityState);
  const handleClose = () => {
    props.history.push('/cohort');
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(props.match.params.id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...cohortEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          type: 'GENERAL',
          visibility: 'PUBLIC',
          membershipType: 'OPEN',
          state: 'NORMAL',
          ...cohortEntity,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="dotsliveApiApp.cohort.home.createOrEditLabel" data-cy="CohortCreateUpdateHeading">
            <Translate contentKey="dotsliveApiApp.cohort.home.createOrEditLabel">Create or edit a Cohort</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="cohort-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('dotsliveApiApp.cohort.name')}
                id="cohort-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 64, message: translate('entity.validation.maxlength', { max: 64 }) },
                }}
              />
              <ValidatedField
                label={translate('dotsliveApiApp.cohort.bio')}
                id="cohort-bio"
                name="bio"
                data-cy="bio"
                type="text"
                validate={{
                  maxLength: { value: 1024, message: translate('entity.validation.maxlength', { max: 1024 }) },
                }}
              />
              <ValidatedField label={translate('dotsliveApiApp.cohort.type')} id="cohort-type" name="type" data-cy="type" type="select">
                {cohortTypeValues.map(cohortType => (
                  <option value={cohortType} key={cohortType}>
                    {translate('dotsliveApiApp.CohortType.' + cohortType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('dotsliveApiApp.cohort.meta')}
                id="cohort-meta"
                name="meta"
                data-cy="meta"
                type="text"
                validate={{
                  maxLength: { value: 2048, message: translate('entity.validation.maxlength', { max: 2048 }) },
                }}
              />
              <ValidatedField
                label={translate('dotsliveApiApp.cohort.visibility')}
                id="cohort-visibility"
                name="visibility"
                data-cy="visibility"
                type="select"
              >
                {cohortVisibilityValues.map(cohortVisibility => (
                  <option value={cohortVisibility} key={cohortVisibility}>
                    {translate('dotsliveApiApp.CohortVisibility.' + cohortVisibility)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('dotsliveApiApp.cohort.membershipType')}
                id="cohort-membershipType"
                name="membershipType"
                data-cy="membershipType"
                type="select"
              >
                {cohortMembershipValues.map(cohortMembership => (
                  <option value={cohortMembership} key={cohortMembership}>
                    {translate('dotsliveApiApp.CohortMembership.' + cohortMembership)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('dotsliveApiApp.cohort.categoryId')}
                id="cohort-categoryId"
                name="categoryId"
                data-cy="categoryId"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('dotsliveApiApp.cohort.coverImageUrl')}
                id="cohort-coverImageUrl"
                name="coverImageUrl"
                data-cy="coverImageUrl"
                type="text"
                validate={{
                  maxLength: { value: 255, message: translate('entity.validation.maxlength', { max: 255 }) },
                }}
              />
              <ValidatedField label={translate('dotsliveApiApp.cohort.state')} id="cohort-state" name="state" data-cy="state" type="select">
                {entityStateValues.map(entityState => (
                  <option value={entityState} key={entityState}>
                    {translate('dotsliveApiApp.EntityState.' + entityState)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('dotsliveApiApp.cohort.oneLinerStatus')}
                id="cohort-oneLinerStatus"
                name="oneLinerStatus"
                data-cy="oneLinerStatus"
                type="text"
                validate={{
                  maxLength: { value: 255, message: translate('entity.validation.maxlength', { max: 255 }) },
                }}
              />
              <ValidatedField
                label={translate('dotsliveApiApp.cohort.organizationId')}
                id="cohort-organizationId"
                name="organizationId"
                data-cy="organizationId"
                type="text"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/cohort" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CohortUpdate;
