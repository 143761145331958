import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './video-feedback.reducer';

export const VideoFeedbackDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const videoFeedbackEntity = useAppSelector(state => state.videoFeedback.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="videoFeedbackDetailsHeading">
          <Translate contentKey="dotsliveApiApp.videoFeedback.detail.title">VideoFeedback</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{videoFeedbackEntity.id}</dd>
          <dt>
            <span id="comment">
              <Translate contentKey="dotsliveApiApp.videoFeedback.comment">Comment</Translate>
            </span>
          </dt>
          <dd>{videoFeedbackEntity.comment}</dd>
          <dt>
            <Translate contentKey="dotsliveApiApp.videoFeedback.feedbackRating">Feedback Rating</Translate>
          </dt>
          <dd>{videoFeedbackEntity.feedbackRating ? videoFeedbackEntity.feedbackRating.id : ''}</dd>
          <dt>
            <Translate contentKey="dotsliveApiApp.videoFeedback.video">Video</Translate>
          </dt>
          <dd>{videoFeedbackEntity.video ? videoFeedbackEntity.video.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/video-feedback" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/video-feedback/${videoFeedbackEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default VideoFeedbackDetail;
