export interface IBadge {
  id?: number;
  scope?: string | null;
  level?: number | null;
  name?: string | null;
  refName?: string | null;
  description?: string | null;
  image?: string | null;
  cohortId?: number | null;
}

export const defaultValue: Readonly<IBadge> = {};
