import cohort from 'app/entities/cohort/cohort.reducer';
import cohortMember from 'app/entities/cohort-member/cohort-member.reducer';
import userSettings from 'app/entities/user-settings/user-settings.reducer';
import userInterest from 'app/entities/user-interest/user-interest.reducer';
import badge from 'app/entities/badge/badge.reducer';
import work from 'app/entities/work/work.reducer';
import school from 'app/entities/school/school.reducer';
import badgeRequirement from 'app/entities/badge-requirement/badge-requirement.reducer';
import userTag from 'app/entities/user-tag/user-tag.reducer';
import organization from 'app/entities/organization/organization.reducer';
import organizationMember from 'app/entities/organization-member/organization-member.reducer';
import video from 'app/entities/video/video.reducer';
import videoCategory from 'app/entities/video-category/video-category.reducer';
import videoCategoryRatingItem from 'app/entities/video-category-rating-item/video-category-rating-item.reducer';
import videoFeedback from 'app/entities/video-feedback/video-feedback.reducer';
import videoFeedbackRating from 'app/entities/video-feedback-rating/video-feedback-rating.reducer';
import videoRating from 'app/entities/video-rating/video-rating.reducer';
import videoLike from 'app/entities/video-like/video-like.reducer';
import walletAddress from 'app/entities/wallet-address/wallet-address.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  cohort,
  cohortMember,
  userSettings,
  userInterest,
  badge,
  work,
  school,
  badgeRequirement,
  userTag,
  organization,
  organizationMember,
  video,
  videoCategory,
  videoCategoryRatingItem,
  videoFeedback,
  videoFeedbackRating,
  videoRating,
  videoLike,
  walletAddress,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
