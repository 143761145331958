import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './video-rating.reducer';

export const VideoRatingDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const videoRatingEntity = useAppSelector(state => state.videoRating.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="videoRatingDetailsHeading">
          <Translate contentKey="dotsliveApiApp.videoRating.detail.title">VideoRating</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{videoRatingEntity.id}</dd>
          <dt>
            <span id="videoFeedbackId">
              <Translate contentKey="dotsliveApiApp.videoRating.videoFeedbackId">Video Feedback Id</Translate>
            </span>
          </dt>
          <dd>{videoRatingEntity.videoFeedbackId}</dd>
          <dt>
            <span id="ratingItemId">
              <Translate contentKey="dotsliveApiApp.videoRating.ratingItemId">Rating Item Id</Translate>
            </span>
          </dt>
          <dd>{videoRatingEntity.ratingItemId}</dd>
          <dt>
            <span id="rating">
              <Translate contentKey="dotsliveApiApp.videoRating.rating">Rating</Translate>
            </span>
          </dt>
          <dd>{videoRatingEntity.rating}</dd>
          <dt>
            <Translate contentKey="dotsliveApiApp.videoRating.feedback">Feedback</Translate>
          </dt>
          <dd>{videoRatingEntity.feedback ? videoRatingEntity.feedback.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/video-rating" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/video-rating/${videoRatingEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default VideoRatingDetail;
