import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import VideoFeedbackRating from './video-feedback-rating';
import VideoFeedbackRatingDetail from './video-feedback-rating-detail';
import VideoFeedbackRatingUpdate from './video-feedback-rating-update';
import VideoFeedbackRatingDeleteDialog from './video-feedback-rating-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={VideoFeedbackRatingUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={VideoFeedbackRatingUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={VideoFeedbackRatingDetail} />
      <ErrorBoundaryRoute path={match.url} component={VideoFeedbackRating} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={VideoFeedbackRatingDeleteDialog} />
  </>
);

export default Routes;
