import { IVideoFeedback } from 'app/shared/model/video-feedback.model';

export interface IVideoRating {
  id?: number;
  videoFeedbackId?: number | null;
  ratingItemId?: number | null;
  rating?: number | null;
  feedback?: IVideoFeedback | null;
}

export const defaultValue: Readonly<IVideoRating> = {};
