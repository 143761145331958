import dayjs from 'dayjs';

export interface IWalletAddress {
  id?: number;
  type?: string | null;
  address?: string | null;
  userId?: string | null;
  createdAt?: string | null;
}

export const defaultValue: Readonly<IWalletAddress> = {};
