import { OrganizationRole } from 'app/shared/model/enumerations/organization-role.model';

export interface IOrganizationMember {
  id?: number;
  userId?: string | null;
  organizationId?: number | null;
  role?: OrganizationRole | null;
}

export const defaultValue: Readonly<IOrganizationMember> = {};
