import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './cohort.reducer';

export const CohortDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const cohortEntity = useAppSelector(state => state.cohort.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="cohortDetailsHeading">
          <Translate contentKey="dotsliveApiApp.cohort.detail.title">Cohort</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{cohortEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="dotsliveApiApp.cohort.name">Name</Translate>
            </span>
          </dt>
          <dd>{cohortEntity.name}</dd>
          <dt>
            <span id="bio">
              <Translate contentKey="dotsliveApiApp.cohort.bio">Bio</Translate>
            </span>
          </dt>
          <dd>{cohortEntity.bio}</dd>
          <dt>
            <span id="type">
              <Translate contentKey="dotsliveApiApp.cohort.type">Type</Translate>
            </span>
          </dt>
          <dd>{cohortEntity.type}</dd>
          <dt>
            <span id="meta">
              <Translate contentKey="dotsliveApiApp.cohort.meta">Meta</Translate>
            </span>
          </dt>
          <dd>{cohortEntity.meta}</dd>
          <dt>
            <span id="visibility">
              <Translate contentKey="dotsliveApiApp.cohort.visibility">Visibility</Translate>
            </span>
          </dt>
          <dd>{cohortEntity.visibility}</dd>
          <dt>
            <span id="membershipType">
              <Translate contentKey="dotsliveApiApp.cohort.membershipType">Membership Type</Translate>
            </span>
          </dt>
          <dd>{cohortEntity.membershipType}</dd>
          <dt>
            <span id="categoryId">
              <Translate contentKey="dotsliveApiApp.cohort.categoryId">Category Id</Translate>
            </span>
          </dt>
          <dd>{cohortEntity.categoryId}</dd>
          <dt>
            <span id="coverImageUrl">
              <Translate contentKey="dotsliveApiApp.cohort.coverImageUrl">Cover Image Url</Translate>
            </span>
          </dt>
          <dd>{cohortEntity.coverImageUrl}</dd>
          <dt>
            <span id="state">
              <Translate contentKey="dotsliveApiApp.cohort.state">State</Translate>
            </span>
          </dt>
          <dd>{cohortEntity.state}</dd>
          <dt>
            <span id="oneLinerStatus">
              <Translate contentKey="dotsliveApiApp.cohort.oneLinerStatus">One Liner Status</Translate>
            </span>
          </dt>
          <dd>{cohortEntity.oneLinerStatus}</dd>
          <dt>
            <span id="organizationId">
              <Translate contentKey="dotsliveApiApp.cohort.organizationId">Organization Id</Translate>
            </span>
          </dt>
          <dd>{cohortEntity.organizationId}</dd>
        </dl>
        <Button tag={Link} to="/cohort" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/cohort/${cohortEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default CohortDetail;
