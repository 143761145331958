export enum OrganizationRole {
  OWNER = 'OWNER',

  MEMBER = 'MEMBER',

  ADMIN = 'ADMIN',

  MODERATOR = 'MODERATOR',

  RECRUITER = 'RECRUITER',
}
