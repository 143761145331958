export interface IUserSettings {
  id?: number;
  userId?: string | null;
  browserEnable?: boolean | null;
  emailEnable?: boolean | null;
  notification?: string | null;
  meta?: string | null;
}

export const defaultValue: Readonly<IUserSettings> = {
  browserEnable: false,
  emailEnable: false,
};
