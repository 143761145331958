import { ICohortMember } from 'app/shared/model/cohort-member.model';
import { CohortType } from 'app/shared/model/enumerations/cohort-type.model';
import { CohortVisibility } from 'app/shared/model/enumerations/cohort-visibility.model';
import { CohortMembership } from 'app/shared/model/enumerations/cohort-membership.model';
import { EntityState } from 'app/shared/model/enumerations/entity-state.model';

export interface ICohort {
  id?: number;
  name?: string;
  bio?: string | null;
  type?: CohortType;
  meta?: string | null;
  visibility?: CohortVisibility | null;
  membershipType?: CohortMembership | null;
  categoryId?: number;
  coverImageUrl?: string | null;
  state?: EntityState | null;
  oneLinerStatus?: string | null;
  organizationId?: number | null;
  members?: ICohortMember[] | null;
}

export const defaultValue: Readonly<ICohort> = {};
