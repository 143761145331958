import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import CohortMember from './cohort-member';
import CohortMemberDetail from './cohort-member-detail';
import CohortMemberUpdate from './cohort-member-update';
import CohortMemberDeleteDialog from './cohort-member-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={CohortMemberUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={CohortMemberUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={CohortMemberDetail} />
      <ErrorBoundaryRoute path={match.url} component={CohortMember} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={CohortMemberDeleteDialog} />
  </>
);

export default Routes;
