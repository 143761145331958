import { OrganizationType } from 'app/shared/model/enumerations/organization-type.model';

export interface IOrganization {
  id?: number;
  name?: string | null;
  type?: OrganizationType | null;
  identifier?: string | null;
  description?: string | null;
  billingEmail?: string | null;
  contactEmail?: string | null;
  websiteUrl?: string | null;
  logoUrl?: string | null;
  linkedinUrl?: string | null;
  stripeCustomerId?: string | null;
}

export const defaultValue: Readonly<IOrganization> = {};
